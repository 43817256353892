/**
 * @param {any} event
 * @return {string}
 */
exports.default = function parseEvent(event) {
	try {
		if (event && event.data && typeof event.data === 'string') {
			const data = JSON.parse(event.data, true) || {};
			switch (true) {
				case (typeof data.karma_changed === 'boolean'):
					return ['karma_changed', data];
				case Boolean(data.height && Number(data.height) && data.from !== 'chat' && event.origin.match('ria.com')):
					return ['height', data];
				default:
					return ['', data];
			}
		}
	} catch (error) {
		console.error(error);
	}
	return ['', {}];
};
